<template>
  <a-layout id="components-layout-demo-top-side-2">
    <a-layout-header class="header">
      <div class="logo" >灵活用工平台</div>
      <div class="user-item">
        <router-link :to="{path: '/user/login'}">
          <a-button icon="login" size="small" type="primary">登陆</a-button>
        </router-link>
      </div>
    </a-layout-header>
    <div class="container">
      <div class="wrapper">
        <a-row :gutter="10">
          <a-col :span="12">
            <a-page-header title="未接项目" :backIcon="false"></a-page-header>
            <home-table type="task" :hasSearch="true" :status="1"></home-table>
          </a-col>
          <a-col :span="12">
            <a-page-header title="服务商列表" :backIcon="false"></a-page-header>
            <home-table type="user" :hasSearch="true" :status="0"></home-table>
          </a-col>
          <a-col :span="24">
            <a-page-header title="已完成项目" :backIcon="false"></a-page-header>
            <home-table type="task" :hasSearch="false" :status="2"></home-table>
          </a-col>
          <a-col :span="24">
            <a-page-header title="进行中项目" :backIcon="false"></a-page-header>
            <home-table type="task" :hasSearch="false" :status="3"></home-table>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-layout>
</template>

<script>

import HomeTable from './components/HomeTable.vue'
export default {
  name: 'Index',
  components: {
    HomeTable
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>

#components-layout-demo-top-side-2 {
  .header {
    padding-left: 24px;
  }
  .logo {
    width: 176px;
    color: #fff;
    font-size: 24px;
    float: left;
  }
} 
.user-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .username {
    color: #fff;
    margin: 0 25px 0 5px;
    font-size: 16px;
  }
}
  .container {
    padding: 24px;
  }
</style>
