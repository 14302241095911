<template>
  <div class="table-container">
    <a-form layout="inline" :form="form" v-if="hasSearch">
      <a-form-item label="标签">
        <a-select style="width: 170px" v-decorator="['lable_id']" :allowClear="true" show-search :filter-option="filterOption">
          <a-select-option v-for="item in lableData" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="handleSearch">搜索</a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      row-key="id"
      :data-source="tableData"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange">
        <template slot="status" slot-scope="text">
          {{text | formateStatus()}}
        </template>
        <template slot="time" slot-scope="text">
          {{text | formateDate()}}
        </template>
        <template slot="timeWithNoDetail" slot-scope="text">
          {{text | formateDate(true)}}
        </template>
        <template slot="taskOperate" slot-scope="text, item">
          <router-link :to="{path: '/index/taskDetail', query: {id: item.id}}">查看</router-link>
        </template>
        <template slot="userOperate" slot-scope="text, item">
          <router-link :to="{path: '/index/userDetail', query: {id: item.id}}">查看</router-link>&nbsp;
        </template>
      </a-table>
  </div>
</template>

<script>
import { taskList, userList, lableData } from '@/utils/api'
const taskColumns = [
  {
    title: '订单号',
    dataIndex: 'order_no'
  },
  {
    title: '任务名称',
    dataIndex: 'task_name'
  },
  {
    title: '开始时间',
    dataIndex: 'start_time',
    scopedSlots: { customRender: 'timeWithNoDetail' },
  },
  {
    title: '项目周期（天）',
    dataIndex: 'day'
  },
  {
    title: '项目服务费',
    dataIndex: 'task_money'
  },
  {
    title: '创建时间',
    dataIndex: 'add_time',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  }
]
const taskColumnsHasSearch = [
  {
    title: '任务名称',
    dataIndex: 'task_name'
  },
  {
    title: '开始时间',
    dataIndex: 'start_time',
    scopedSlots: { customRender: 'timeWithNoDetail' },
  },
  {
    title: '项目周期（天）',
    dataIndex: 'day'
  },
  {
    title: '项目服务费',
    dataIndex: 'task_money'
  }
]

const userColumns = [
  {
    title: '服务商',
    dataIndex: 'real_name'
  },
  {
    title: '服务商主体',
    dataIndex: 'company_name'
  },
  // {
  //   title: '统一信用代码',
  //   dataIndex: 'credit_code'
  // },
  {
    title: '月度限额上限(人/元)',
    dataIndex: 'month_money'
  }
]
export default {
  name: 'HomeTable',
  data() {
    return {
      form: this.$form.createForm(this),
      tableParams: {},
      columns: [],
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showTotal: total => `共${total}条数据`
      },
      loading: false,
      lableData: []
    }
  },
  props: {
    type: String,
    hasSearch: Boolean,
    status: Number
  },
  filters: {
    formateDate(val, noDetail) {
      if (val) {
        const date = new Date(parseInt(val) * 1000)
        const y = date.getFullYear()
        const m = date.getMonth() + 1
        const d = date.getDate()
        const h = date.getHours()
        const i = date.getMinutes()
        const s = date.getSeconds()
        return y + '-'
              + (m < 10 ? '0' + m : m) + '-'
              + (d < 10 ? '0' + d : d) +
              (noDetail ? '' : (' '
              + (h < 10 ? '0' + h : h) + ':'
              + (i < 10 ? '0' + i : i) + ':'
              + (s < 10 ? '0' + s : s)
              ))
      }
      return ''
    },
    formateStatus(val) {
      let str = ''
      switch (val) {
        case 0:
          str = '已创建项目'
          break;
        case 1:
          str = '已接项目'
          break;
        case 2:
          str = '已完成项目'
          break;
        case 3:
          str = '公司已支付'
          break;
        case 4:
          str = '服务商已收款'
          break;
        case 5:
          str = '公司已申请开票'
          break;
        case 6:
          str = '服务商已开票'
          break;
        case 7:
          str = '服务已付款到分包商'
          break;
        case 8:
          str = '分包商已收款并开票'
          break;
      }
      return str
    }
  },
  mounted() {
    if (this.type == 'task') {
      if (this.hasSearch) {
        this.columns = taskColumnsHasSearch
      } else {
        this.columns = taskColumns
      }
    } else if (this.type == 'user') {
      this.columns = userColumns
    }
    this.getTableData()
    if (this.hasSearch) {
      this.getLableData()
    }
  },
  methods: {
    getLableData () {
      lableData({
        type: this.type == 'task' ? 1 : 2,
        switch: 4
      }).then((res) => {
        this.lableData = res.info
      })
    },
    filterOption (input, option) {
      return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0)

    },
    handleSearch() {
      this.form.validateFields((err, values) => {
        this.tableParams = { ...values }
        const pagination = { ...this.pagination }
        pagination.current = 1,
        this.pagination = pagination
        this.getTableData()
      })
    },
    handleReset() {
      this.form.setFieldsValue({
        lable_id: ''
      })
      this.handleSearch()
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.getTableData()
    },
    getTableData() {
      this.loading = true
      const pagination = { ...this.pagination }
      if (this.type == 'task') {
        taskList({
          pageCount: pagination.pageSize,
          page: pagination.current,
          ...this.tableParams,
          fw: this.status
        }).then(res => {
          if (pagination.current == 1){
            pagination.total = res.count || 0
            this.pagination = pagination
          }
          this.tableData = res.task
          this.loading = false
        })
      } else if (this.type == 'user') {
        userList({
          role_id: 2,
          pageCount: pagination.pageSize,
          page: pagination.current,
          ...this.tableParams
        }).then(res => {
          if (pagination.current == 1){
            pagination.total = res.count || 0
            this.pagination = pagination
          }
          this.tableData = res.user
          this.loading = false
        })
      }
    }
  }
}
</script>